.col {
  height: 68px;
  width: 176px;
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  padding: 0px;
  margin: 24px 0px 0px 0px;
  z-index: 1;

  display: none;
  position: absolute;
}

.row {
  height: 24px;
  width: 176px;
  display: flex;
  flex-flow: row;
  margin: 0px;
  padding: 0px;
}

.row p {
  height: 24px;
  width: 112px;
  font-size: 14px;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #353755;
  opacity: 1;
  text-align: left;
  font-weight: 600;
  margin: 0px 8px 0px 16px;
}

.icon {
  height: 16px;
  width: 16px;
  margin: 4px 0px 4px 0px;
  padding: 1px 4px;
  font-size: 9px;
}

.row:hover .col {
  display: block;
}
