.container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  padding: 104px 0 64px 0;
  margin-bottom: 80px;
}

.dataList {
  width: calc(55% - 64px);
  height: 944px;
  margin: 32px 0;
  padding: 32px;
  background-color: white;
  flex-wrap: wrap;
  display: table;
}
.buttonRow {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.buttonCol {
  display: flex;
  flex-flow: column;
  gap: 8px;
}

.dataSet {
  width: 20%;
  height: 100%;
  background-color: white;
  margin: 32px 0px;
  padding: 32px;
  padding-bottom: 80px;
  position: fixed;
  right: 0;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
}

.title {
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
}

.sidebar {
  margin: 0;
  padding: 0;
  width: 25%;
  height: 100%;
  margin-right: 32px;
}

.centering {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 200px;
}

.itemList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

@media (max-width: 1500px) {
  .sidebar {
    display: none;
  }

  .dataList {
    width: calc(80% - 64px);
    margin: 32px;
  }
}

@media (max-width: 1000px) {
  .sidebar {
    display: none;
  }

  .dataSet {
    display: none;
  }
  .dataList {
    width: 100%;
    height: 944px;
    margin: 16px 0;
    padding: 32px;
    background-color: white;
    flex-wrap: wrap;
    display: table;
  }
}

@media (min-width: 2000px) {
  .itemList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 1000px;
  }
}
