.container tr:nth-child(even) {
  max-height: 40px;
  height: 40px;
  background-color: rgb(221, 244, 243, 0.5);
}

.container tr:nth-child(odd) {
  width: 100%;
  height: auto;
  background-color: #ddf4f3;
}
.container th {
  text-align: left;
  width: 40%;
  font-weight: 600;
  height: 100%;
  padding: 10px 24px;
}
.container td {
  width: 60%;
  text-align: left;
  font-weight: normal;
  height: 100%;
  padding: 10px 16px;
}

.container table {
  width: 784px;
  font-family: sans-serif;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 100%;
}
.header {
  width: 784px;
  height: 40px;
  letter-spacing: 0px;
  color: #5bd3c7;
  text-transform: uppercase;
  opacity: 1;
  padding: 10px 357px 10px 43px;
  font-family: sans-serif;
  font-size: 14px;
  text-align: left;
}

.head {
  width: 784px;
  height: 40px;
  padding: 10px 436px 10px 44px;
}

.container {
  overflow-x: scroll;
  width: 100%;
  padding: 16px;
  box-shadow: 0px 2px 4px #5fada54f;
  border-radius: 4px;
  opacity: 1;
  margin: 8px 0px 0px 0px;
}
