.header {
  height: 104px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 3px 6px #0000008a;
  opacity: 1;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  position: fixed;
  align-items: center;
}

.logos {
  display: flex;
  height: 68%;
  align-items: center;
  margin-left: 3%;
}
.language {
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 5% 0 0;
}

@media screen and (max-width: 1000px) and (orientation: landscape) {
  .header {
    height: 14%;
  }

  .language {
    display: none;
  }
}
