.big {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  background-color: #e0e4ee;
  border: none;
  padding: 0 24px;
}
.medium {
  width: 592px;
  height: 40px;
  border-radius: 8px;
  background-color: #e0e4ee;
  border: none;
}

.small {
  width: 392px;
  height: 40px;
  border-radius: 8px;
  background-color: #e0e4ee;
  border: none;
}
.container {
  display: flex;
  margin: 4px 0 8px 0;
}

.icon {
  margin: 7px 0px 7px -42px;
  font-size: 26px;
  height: 26px;
  width: 26px;
  color: #bec7cf;
}
