.sideBar {
  width: 25%;
  background-color: #353755;
  top: 104px;
  left: 0;
  height: 100%;
  padding: 24px;
  margin: 0;
  opacity: 1;
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
}
