.footer {
  height: 64px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0px -3px 6px #00000029;
  opacity: 1;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  padding-right: 64px;
}

.p {
  margin: 24px 8px;
}
