      .formContainer {
          display: flex;
          flex-direction: row;
          gap: 40px;
          /* width: 1000px; */
      }
      
      .leftForm {
          width: 45%;
          display: flex;
          flex-direction: column;
          flex: auto;
      }
      
      .rightForm {
          width: 45%;
          display: flex;
          flex-direction: column;
          flex: auto;
      }
      /* input {
      display: block;
      box-sizing: border-box;
      border-radius: 4px;
      border: 1px solid white;
      padding: 10px 15px;
      font-size: 14px;
  } */
      /* .materialUIInput {
      display: block;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: white;
      padding: 2px 8px;
      margin-bottom: 10px;
      font-size: 16px;
      border-style: solid;
      border-color: hsl(0, 0%, 80%);
      border-width: 1px;
  } */
      
      .IceCreamType {
          color: black;
      }
      
      .formContainer label {
          line-height: 2;
          text-align: left;
          display: block;
          margin-top: 10px;
          color: black;
          font-size: 14px;
          font-weight: 200;
      }
      
      input:disabled {
          opacity: 0.4;
      }
      
      .permissibleValues MuiButton-startIcon {
          margin-left: 0px;
          margin-right: 0px;
      }
      
      .dialogFooter {
          padding-top: 20px;
      }
      /* .MuiInputBase-multiline {
          padding-left: 8px !important;
      } */
      /* .formContainer .textArea .MuiInputBase-root {
          padding: 8px;
          margin-bottom: 10px;
          border-style: solid;
          border-color: hsl(0, 0%, 80%);
          border-width: 1 px;
          border-radius: 4px;
          resize: vertical;
      } */
      
      textarea {
          padding: 8px;
          margin-bottom: 10px;
          border-style: solid;
          border-color: hsl(0, 0%, 80%);
          border-width: 1 px;
          border-radius: 4px;
          resize: vertical;
          min-height: 80px;
          font: inherit;
          color: rgba(0, 0, 0, 0.87);
      }