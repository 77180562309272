.container {
  width: 100%;
  height: 100%;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
