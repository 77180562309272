.box {
  width: 24px;
  height: 24px;
  margin: 0px;
  padding: 0px;
}

/* .box1 {
  width: 16px;
  height: 12px;
  margin: 6px 4px;
  padding: 0px;
  border: 1px solid #e0e4ee;
  border-radius: 1px;
} */
.box p {
  width: 16px;
  height: 12px;
  margin: 6px 4px;
  padding: 0px;
  color: #bec7cf;
  font-family: sans-serif;
  letter-spacing: 0px;
  font-size: 6px;
  text-align: center;
  border: 1px solid #e0e4ee;
  border-radius: 1px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box p:hover,
.box p:active,
.box p.active {
  width: 16px;
  height: 12px;
  margin: 6px 4px;
  padding: 0px;
  font-family: sans-serif;
  letter-spacing: 0px;
  font-size: 6px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #353755;
  border-radius: 1px;
  background-color: #353755;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.box p.inactive {
  width: 16px;
  height: 12px;
  margin: 6px 4px;
  padding: 0px;
  color: #bec7cf;
  font-family: sans-serif;
  letter-spacing: 0px;
  font-size: 6px;
  text-align: center;
  border: 1px solid #e0e4ee;
  border-radius: 1px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
