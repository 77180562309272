.dataHeader {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border: solid 1px #5bd3c7;
  box-shadow: 0px 2px 4px #28416229;
  border-radius: 4px;
  opacity: 1;
  font: normal normal 14px/32px sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.dataHeaderText {
  min-height: 40px;
  margin: 0;
  padding: 0 0 0 24px;
  position: relative;
  text-align: start;
  font: normal normal 600 14px/32px sans-serif;
  text-transform: uppercase;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.data {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 100%;
  min-height: 40px;
  background-color: #ffffff;
  border-radius: 4px;
  opacity: 1;
  margin: 4px 0 4px 0;
  border-width: 0px;
  border-left: solid 8px white;
  padding: 0;
}

@media (max-width: 1000px) {
  .data {
    max-width: 1000px;
  }
}

.data:hover {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 100%;
  min-height: 40px;
  background-color: #ddf4f3;
  border-width: 0px;
  border-left: solid 8px #5bd3c7;
  border-radius: 4px;
  opacity: 1;
  margin: 4px 0 4px 0;
  padding: 0;
}
.flexbox {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.downloadButton {
  margin-left: -40px;
}

.firstText {
  width: 55%;
  height: 100%;
  margin: 0;
  padding: 0 0 0 16px;
  position: relative;
  text-align: start;
  font: normal normal 14px/32px sans-serif;
}

.SecondText {
  width: 5%;
  height: 100%;
  margin: 0 0 0 24px;
  padding: 0;
  position: relative;
  text-align: start;
  font: normal normal 14px/32px sans-serif;
}

.dataText {
  max-width: 304px;
  height: 100%;
  margin: 0 0 0 16px;
  padding: 0;
  font: normal normal 14px/32px sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dataHover {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 816px;
  height: 40px;
  background-color: #ddf4f3;
  border-radius: 4px;
  opacity: 1;
  text-align: justify;
  margin-top: 8px;
}

.icd10Data {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 392px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 4px;
  opacity: 1;
  text-align: justify;
  margin-top: 8px;
}

.icd10Data:hover {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 392px;
  background-color: #ddf4f3;
  border-radius: 4px;
  opacity: 1;
  text-align: justify;
  margin-top: 8px;
}

.text {
  margin-top: 6px;
  width: 50%;
  font: normal normal 14px/32px sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.snomedListText1 {
  margin: 12px 0px 12px 16px;
  width: 822px;
  font: 14px sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.snomedListText2 {
  margin: 12px 0px 12px 152px;
  width: 140px;
  font: 14px sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.snomedList {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 1272px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 4px;
  opacity: 1;
  text-align: justify;
  align-items: stretch;
  margin-top: 8px;
}
.snomedList:hover {
  box-shadow: 0px 1px 8px #5fada54f;
  width: 1272px;
  height: 40px;
  background-color: #ddf4f3;
  border-radius: 4px;
  opacity: 1;
  text-align: justify;
  align-items: stretch;
  margin-top: 8px;
}
