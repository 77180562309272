.row {
  height: 34px;
  width: 176px;
  display: flex;
  flex-flow: row;
  background-color: #ffffff;
  margin: 0px;
  padding: 5px 24px 5px 8px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
}

.row p {
  height: 16px;
  width: 112px;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #353755;
  opacity: 1;
  text-align: left;
  font-weight: 600;
  margin: 4px 0px 4px 8px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.row:hover {
  height: 34px;
  width: 176px;
  display: flex;
  flex-flow: row;
  background-color: #e0e4ee;
  margin: 0px;
  padding: 5px 24px 5px 8px;
  border: none;
}

.rowHover p {
  height: 16px;
  width: 112px;
  font-size: 11px;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #353755;
  opacity: 1;
  text-align: left;
  font-weight: 600;
  margin: 4px 0px 4px 8px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
