.cyan {
  max-width: 872px;
  height: auto;
  background-color: #affffc;
  border: 0px solid white;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.76;
  border-radius: 8px;
  box-shadow: 3px 3px 5px black;
  margin-top: 8px;
  padding: 6px;
}
.cyan:hover {
  max-width: 872px;
  height: auto;
  background-color: #affffc;
  border: 0px solid white;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 8px;
  box-shadow: 3px 3px 5px black;
  margin-top: 8px;
  padding: 6px;
}
.text {
  margin: 3px 0px 3px 0px;
  width: 50%;
  font-size: 22px;
  color: #353755;
  font-family: "Open Sans";
  margin: 0 0 0 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cyanText {
  height: 100%;
  width: 100%;
  margin: auto;
  font: 20px sans-serif;
  font-weight: normal;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #353755;
}

.cyanText:hover {
  margin: auto;
  font: 20px sans-serif;
  font-weight: 600;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #353755;
}
.darkMediumText {
  margin: 0;
  padding: 8px 16px;
  text-transform: uppercase;
}

.darkMedium {
  width: 100%;
  min-height: 40px;
  padding: 0;
  margin: 14px 0px 8px 0px;
  background-color: #353755;
  border: none;
  color: #5bd3c7;
  align-items: flex-start;
  text-transform: uppercase;
  opacity: 1;
}

.darkSmall {
  width: 100%;
  min-height: 36px;
  background-color: #353755;
  border: none;
  color: #ffffff;
  text-align: left;
  opacity: 1;
  margin: 4px 0 4px 0;
  flex-wrap: wrap;
}
.darkSmall:hover {
  width: 100%;
  min-height: 36px;
  background-color: #242646;
  border: none;
  color: #ffffff;
  text-align: left;
  opacity: 1;
  margin: 4px 0 4px 0;
}
.darkSmallText {
  height: 100%;
  font-weight: normal;
  text-transform: uppercase;
  padding: 7px 0px 7px 16px;
  margin: 0 0 0 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
}

.darkSmallText:hover {
  height: 100%;
  font-weight: 400;
  padding: 7px 0px 7px 16px;
  margin: 0 0 0 0;
  text-transform: uppercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #ffffff;
}

.darkBig {
  max-width: 872px;
  height: auto;
  min-height: 64px;
  height: auto;
  background-color: #353755;
  border: none;
  color: #5bd3c7;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  border-radius: 8px;
  font-family: sans-serif;
  font-size: 22;
  margin-top: 8px;
}
.darkBigText {
  width: auto;
  height: auto;
  text-align: center;
  color: #5bd3c7;
  font-size: 22px;
  font-family: sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 8px;
  margin: 0;
}

.download {
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 0;
  opacity: 1;
  position: relative;
}
.download:hover {
  background-color: #ddf4f3;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  opacity: 1;
  position: relative;
}

.downloadIcon {
  size: 15px;
  color: #c5ccd3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -7px;
}

.downloadIcon:hover {
  size: 15px;
  color: #353755;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -7px 0 0 -7px;
}

.normalButton {
  width: 176px;
  height: 40px;
  border: 1px solid #5bd3c7;
  border-radius: 4px;
  opacity: 1;
  background-color: white;
}

.normalButton:hover {
  width: 176px;
  height: 40px;
  background-color: #e6f3f3;
  border: 1px solid #5bd3c7;
  border-radius: 4px;
  opacity: 1;
}

.normalButtonText {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    12px/14px var(--unnamed-font-family-open-sans);
  text-align: center;
  padding-top: 7px;
  color: #353755;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  height: auto;
  width: 100%;
  box-shadow: 0px 2px 4px #5fada54f;
  border-radius: 4px;
  opacity: 1;
  margin-bottom: 8px;
}
.flexContainer:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: auto;
  width: 100%;
  box-shadow: 0px 2px 4px #5fada54f;
  border-radius: 4px;
  opacity: 1;
  background-color: #ddf4f3;
  margin-bottom: 8px;
}

.dataSetText {
  margin: 7px;
  padding: 0;
}

.colCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
