.title {
  max-width: 756px;
  height: auto;
  font-size: 48px;
  color: #fafafa;
  margin: 0;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
  font-weight: bold;
}

.description {
  max-width: 960px;
  height: auto;
  color: #5bd3c7;
  font-size: 18px;
  text-align: center;
  margin-top: 8px;
  font-weight: 600;
  margin-bottom: 100px;
}

.background {
  padding: 275px 0;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
}

.col {
  width: 50%;
  padding: 0px 8px;
}
